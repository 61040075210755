/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #444444 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #ffffff10;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 0px;
  border: 0px none #ffffff;
}

body {
  padding-right: 0px !important;
  overflow-y: scroll !important;
}
