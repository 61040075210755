h2 {
  display: flex;
  gap: 12px;
  cursor: pointer;
}

.arrowOpen .arrowClose {
  display: flex;
  align-items: center;
}

.arrowOpen svg {
  transform: rotate(0deg);
  position: relative;
  transition-duration: 0.2s;
  transition-property: transform;
}

.arrowClose svg {
  transform: rotate(-90deg);
  position: relative;
  transition-duration: 0.2s;
  transition-property: transform;
}

.accordion {
  transition: all 0.2s ease-in-out;
}

.accordionClose {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.accordionOpen {
  visibility: visible;
  opacity: 1;
  height: 100%;
}
