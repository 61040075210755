.css-bj2p8a-MuiPaper-root-MuiCard-root {
  overflow: visible !important;
}

.options_container {
  position: absolute;
  top: 60px;
  background-color: #1d1e20;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  border-radius: 6px;
  width: 176.19px;
}

.search {
  margin: 8px 4px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.option {
  margin: 0 4px;
  display: flex;
  gap: 12px;
  white-space: nowrap;
}

.input-root.error .MuiInputBase-root,
.input-root.error .MuiFilledInput-root::before {
  border-color: red !important;
}

.input-root.warning .MuiInputBase-root,
.input-root.warning .MuiFilledInput-root::before {
  border-color: orange !important;
}

.input-root.error .MuiInputBase-root:hover fieldset,
.input-root.error .MuiFilledInput-root:hover::before {
  border-color: darkred !important;
}

.input-root.warning .MuiInputBase-root:hover fieldset,
.input-root.warning .MuiFilledInput-root:hover::before {
  border-color: darkorange !important;
}

.input-root.error .MuiInputBase-root.Mui-focused fieldset,
.input-root.error .MuiFilledInput-root.Mui-focused::before {
  border-color: red !important;
}

.input-root.warning .MuiInputBase-root.Mui-focused fieldset,
.input-root.warning .MuiFilledInput-root.Mui-focused::before {
  border-color: orange !important;
}

.input-root.error .MuiInputLabel-root {
  color: red !important;
}

.input-root.warning .MuiInputLabel-root {
  color: orange !important;
}

.input-root.error .MuiInputLabel-root.Mui-focused {
  color: red !important;
}

.input-root.warning .MuiInputLabel-root.Mui-focused {
  color: orange !important;
}

.input-root.error .MuiFormHelperText-root {
  color: red !important;
}

.input-root.warning .MuiFormHelperText-root {
  color: orange !important;
}